import React, {useEffect, useState, createContext, useContext } from "react";
import "./Main.css";
import Banner from "../Banner/Banner";
import Items from "../Items/Items";
import Cart from "../Cart/Cart";
import PaymentScreen from "../Payment/Payment";
import axios from "axios";
import { useHireContext } from '../../App.js';

var temp = new Array();
function Main(params) {
  const [darkMode, setDarkMode] = useState(params.Mode);
  // const [cartItems, setCartItems] = useState(
  //   localStorage.getItem("items") ? JSON.parse(localStorage.getItem("items")) : []
  // );
  const [cartItems, setCartItems] = useState([]);
  const [price, setPrice] = useState();
  const [Code, setCode] = useState('USD');
  const [Amount, setAmount] = useState();
  const [Stock, setStock] = useState();
  //const [Total, setTotal] = useState(Number(localStorage.getItem("bill")));
  const [Total, setTotal] = useState(0);
  //const [TotalInCurr, setTotalInCurr] = useState(Number(localStorage.getItem("bill")));
  const [TotalInCurr, setTotalInCurr] = useState(0);
  const [TotalInEur, setTotalInEur] = useState(null);
  const [totalInCurrOtherProducts, setTotalInCurrOtherProducts] = useState(0);
  // const {cartItems, setCartItems} = useHireContext();
  const {
    currentCurrency,
    setCurrentCurrency
   } = useHireContext();

  useEffect(() => {
    updateBillInEur(Total);
  },[]);

  useEffect(() => {
    setDarkMode(params.Mode);
    if (TotalInEur !== null) {
      //updateBillInEur(TotalInEur);
    }
  });

  useEffect(() => {
    updateBillInCurr(TotalInCurr);
  }, [currentCurrency]);

  function updateBillInCurr(total) {
    var cur = currentCurrency;
    if (total !== 0) {
      if (cur !== undefined) {
        var data2;
        data2 = {
          from: Code,
          to: cur,
          amount: total,
          value: 6,
        };
      } else {
        data2 = {
          from: Code,
          to: "USD",
          amount: total,
          value: 6,
        };
      }
      if (data2.from !== data2.to) {
        axios.post("https://ozchest.com/convert ", data2).then((response2) => {
          setTotalInCurr(convertDecimal(response2.data.cur));
          setCode(currentCurrency);
        });
      } else {
        setTotalInCurr(convertDecimal(total));
      }
    } else {
      setTotalInCurr(0);
    }
  }

  function convertDecimal(total) {
    // Ensure total is a number
    const parsedTotal = parseFloat(total);
  
    // Check if parsedTotal is a valid number
    if (!isNaN(parsedTotal)) {
      // Round to one digit after the decimal point
      const roundedTotal = parsedTotal.toFixed(3);
  
      // If you need it as a number, you can convert it back
      const finalTotal = parseFloat(roundedTotal);
  
      return finalTotal;
    } else {
      console.log('Invalid total value');
      return null; // or handle the error accordingly
    }
  }

  function updateBillInEur(total) {
    if (total !== 0) {
      if (currentCurrency !== undefined) {
        var data2;
        data2 = {
          from: Code,
          to: "USD",
          amount: total,
          value: 6,
        };

        axios.post("https://ozchest.com/convert ", data2).then((response2) => {
          setTotalInEur(convertDecimal(response2.data.cur));
        });
      }
    } else {
      setTotalInEur(0);
    }
  }

  function updateOtherBillInCurr(total) {
    var cur = currentCurrency;
    if (total !== 0) {
      if (cur !== undefined) {
        var data2;
        data2 = {
          from: "USD",
          to: cur,
          amount: total,
          value: 6,
        };
      } else {
        data2 = {
          from: "USD",
          to: "USD",
          amount: total,
          value: 6,
        };
      }
      if (data2.from !== data2.to) {
        axios.post("https://ozchest.com/convert ", data2).then((response2) => {
          setTotalInCurrOtherProducts(convertDecimal(response2.data.cur));
        });
      } else {
        setTotalInCurrOtherProducts(convertDecimal(total));
      }
    } else {
      setTotalInCurrOtherProducts(0);
    }
  }

  function updateCart(items) {
    setCartItems(items);
    var total = 0, otherTotal = 0;
    for (var i = 0; i < items.length; i++) {
      total = total + items[i].sale;
    }
    for (var i = 0; i < items.length; i++) {
      if (items[i]. brand !== 'BitJem') {
        otherTotal = otherTotal + items[i].sale;
      }
    }
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    localStorage.setItem("items", JSON.stringify(temp));
    localStorage.setItem("bill", total);
    setTotal(total);
    updateBillInCurr(total);
    updateBillInEur(total);
    updateOtherBillInCurr(otherTotal);
    window.dispatchEvent(new Event('storage'));
  }

  function changeCart(cartitems, price, code, amount, stock) {
    var temp = cartItems ? cartItems : [];
    for (var i = 0; i < amount; i++) {
      temp.push(cartitems);
    }
    setCartItems(temp);
    setPrice(price);
    setAmount(amount);
    setStock(stock);
    localStorage.setItem("items", JSON.stringify(temp));
    window.dispatchEvent(new Event('storage'));

    var total = 0, otherTotal = 0;
    for (var i = 0; i < temp.length; i++) {
      total = total + temp[i].sale;
    }
    for (var i = 0; i < temp.length; i++) {
      if (temp[i]. brand !== 'BitJem') {
        otherTotal = otherTotal + temp[i].sale;
      }
    }
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    localStorage.setItem("bill", total);
    setTotal(total);
    updateBillInCurr(total);
    updateBillInEur(total);
    updateOtherBillInCurr(otherTotal);
  }
  return (
    <div className="main-container" id="main-container">
      <div className="main-box">
        
        <Banner Mode={darkMode}></Banner>
        <Cart
          Mode={darkMode}
          cart={cartItems}
          setcart={updateCart}
          total={Total}
          items={cartItems}
          price={price}
          code={Code}
          amount={Amount}
          stock={Stock}
          totalincurr={TotalInCurr}
          totalineur={TotalInEur}
          othertotalincurr={totalInCurrOtherProducts}
        ></Cart>
        <Items
          Mode={darkMode}
          setCart={changeCart}
          current={currentCurrency}
        ></Items>
      </div>
    </div>
  );
}

export default React.memo(Main);
