import React, { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { useAlert } from "react-alert";
import "./Paystack.css";

const pKey = process.env.REACT_APP_PKEY;
const Paystack = (params) => {
  const [amountInNGN, setAmountInNGN] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(1);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const handleUpdateBalance = (reference) => {
    const data = {
      user: sessionStorage.getItem("key"),
      amount: params.amount
    };

    axios.post("https://ozchest.com/addBalance", data)
      .then((res) => {
        if (res) {
          sessionStorage.setItem('balance', res.data.balance);
          alert.success('Balance added successfully');
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error('Error updating balance:', error.message);
      });
  };

  const handleDummyButtonClick = async (e) => {
    setLoading(true);
    const data = {
      currency: "USDTNGN",
    };

    try {
      const response = await axios.post("https://ozchest.com/getCryptoRate", data);

      if (response.data && response.data.cur) {
        let erate = 1 / response.data.cur;
        let amount2 = (Number(params.amount) / erate) * 100;
        setExchangeRate(erate);
        setAmountInNGN(Number(amount2));

        setTimeout(() => {
          setLoading(false);
          const paystackButtonDiv = document.getElementById("paystackButton");
          const paystackButton = paystackButtonDiv?.querySelector('button');
          if (paystackButton) {
            paystackButton.click();
          }
        }, 2000);
      }
    } catch (error) {
      console.error('Error fetching exchange rate:', error.message);
    }
  };

  return (
    <div className="refill-div">
      {loading ? (
      <div className="spinner-border" role="status">
      </div>
    ) : (
      <button className='refill-btn2' onClick={(e)=>handleDummyButtonClick(e)}>Refill</button>
    )}

      <div id="paystackButton" style={{display: 'none'}}>
        <PaystackButton 
        reference={(new Date()).getTime().toString()}
        email={sessionStorage.getItem('email')}
        amount={amountInNGN}
        publicKey={pKey}
        text="Refill"
        onSuccess={(reference) => {
          handleUpdateBalance(reference);
        }}
        onClose={() => {
          console.log('exittt');
        }}
        />
      </div>
    </div>
  );
};

export default Paystack;
